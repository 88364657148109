@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,300;0,400;0,500;0,700;0,800;1,600;1,700&display=swap");
* {
  font-family: "Open Sans", sans-serif;
  padding: 0;
}

body {
  font-size: 13px;
}

h1,
h2,
h3,
h5,
h4,
h6 {
  color: #000;
}

a:hover,
a:active {
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

p {
  color: #000;
  font-size: 12px;
}

.side__content {
  padding-right: calc(40px - 1.5rem);
  padding-left: calc(100px - 1.8rem);
  margin-top: 4rem;
  transition: all 0.3s ease-in-out;
  min-height: calc(100vh - 4rem);
  padding-top: 1rem;
  /* padding-bottom: 1rem; */
}

/* body {
  overflow-x: hidden;
} */

.sup-col {
  color: red;
}

.label-radio {
  height: 30px;
  width: 140px;
  border: 1px solid var(--MainColor);
  margin: auto;
  border-radius: 3px;
  position: relative;
  color: var(--MainColor);
  background-color: white;
  transition: 0.5s;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
  font-size: 12px;
}

input[type="radio"]:checked + label {
  background-color: var(--MainColor);
  color: #ffffff !important;
}

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
}

.TUEFO {
  padding: 0px !important;
}

.close-pdf {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 15px;
  right: 5px;
  background: #ff4949;
  cursor: pointer;
}

/* CSS for button thet accepts the excel value */
.input-xlsx {
  display: flex;
}

.input-xlsx > input {
  display: none;
}

.input-xlsx > label {
  border: 1px solid var(--button-color);
  border-radius: 3px;
  padding: 4.5px;
  font-size: 13px;
  background-color: var(--button-color);
  color: #ffffff !important;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 0 !important;
}

.form-group {
  margin-top: 1rem;
}

.jRYfDi {
  max-height: max-content !important;
}

.bvxQGL {
  color: #000 !important;
  fill: #000 !important;
}

.bvxQGL:disabled {
  color: rgba(0, 0, 0, 0.18) !important;
  fill: rgba(0, 0, 0, 0.18) !important;
}

@media (max-width: 1199.9px) {
  .side__content .dashAtt:nth-child(2),
  .side__content .dashAtt:last-child {
    padding-left: 30px !important;
  }
}
@media (min-width: 959.9px) {
  .dots-icon,
  #mob-nav,
  .ham-menu {
    display: none;
  }
  .padding {
    padding-left: calc(300px - 1.8rem);
  }
  .none {
    display: none !important;
  }
}
@media (max-width: 959.9px) {
  .login__page {
    overflow: hidden;
  }
  /* .login__page .login-form-wrapper,
  .login__page .login-img-wrapper {
    width: 75%;
    margin: 0 auto;
    padding-right: 0 !important;
    padding-left: 30px !important;
  } */
  .logo {
    width: 200px !important;
    margin-left: 2rem;
  }

  .side__panel {
    width: 0 !important;
  }
  .side__content {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .dashboard-wrapper {
    width: 75%;
    margin: 0 auto !important;
  }
  .side__content .dashAtt:nth-child(2),
  .side__content .dashAtt:last-child {
    padding-left: 1rem !important;
  }
  .dashOrg {
    padding-left: 1rem;
  }
  .dashboard-wrapper .dasboard-wrap {
    padding: 0 !important;
  }
  .total-employee .total-employee-box {
    margin-bottom: 3rem;
  }
  .total-employee::before {
    display: none;
  }
  .project-details,
  .today-absent {
    padding: 1rem !important;
  }
  nav {
    padding: 0.5rem 0 !important;
  }
  .large-nav {
    display: none;
  }
  .resp {
    justify-content: end;
  }
  .dots-icon {
    cursor: pointer;
  }
  .dots-icon .dots {
    transition: all 0.3s ease-in-out;
  }
  .dots-icon .dots:hover {
    color: #0049ae !important;
  }
  .mob-nav {
    display: none;
  }
  .ham-menu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    cursor: pointer;
  }
  .ham-menu .ham-icon {
    transition: all 0.3s ease-in-out;
  }
  .ham-menu .ham-icon:hover {
    color: #0049ae !important;
  }
  .ham-menu .closed-icon {
    transition: all 0.3s ease-in-out;
  }
  .ham-menu .closed-icon:hover {
    color: #ff4949 !important;
  }
}
@media (max-width: 639.9px) {
  p {
    font-size: 12px;
  }
  .card-title {
    font-size: 20px;
  }
  .login__page {
    padding-top: 2rem;
  }
  .login__page .login-form-wrapper,
  .login__page .login-img-wrapper {
    width: 100% !important;
    margin-top: 3rem !important;
  }
  .login__page .login-form-wrapper::before {
    display: none;
  }
  .login__page .login__img .txt h1 {
    font-size: 35px !important;
  }
  .login__page .login__form {
    padding: 2rem;
  }
  .dashboard-wrapper {
    width: 100%;
  }
  .side__content {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .logo {
    padding-left: 0 !important;
    width: 130px !important;
  }
  .logo a img {
    margin-right: 0 !important;
  }
  #mob-nav {
    right: 9px !important;
  }
  .profile-info {
    flex-direction: column;
    align-items: flex-start !important;
    width: max-content;
  }
  .profile-info h6 {
    padding-left: 0 !important;
  }
  .profile-info p {
    padding-left: 0 !important;
  }
  .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .imgPrv-popup-inner {
    width: 80% !important;
    transform: translate(-50%, -50%) !important;
  }
  #mob-nav {
    width: 80% !important;
  }
}

@media (max-width: 575.9px) {
  .table__button__wrapper {
    position: static !important;
  }
}

@media (max-width: 360.9px) {
  .login__page .login__form {
    padding: 1rem;
  }
  .login__page .login__form form .uk-button {
    width: 75%;
  }
  /* .side__content {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  } */
}

.printBody {
  height: calc(100vh - 34vh) !important;
  overflow: auto;
}

.shiftRoasterTable {
  position: relative;
  width: 100%;
  border-radius: 0;
  overflow-x: auto;
  overflow-y: auto;
  min-height: 0;
  max-height: 360px;
  min-height: 300px;
}

.tableHeight {
  -ms-overflow-style: auto; /* Internet Explorer 10+ */
  scrollbar-width: auto; /* Firefox */
}
.show-scrollbar {
  -ms-overflow-style: auto; /* Internet Explorer 10+ */
  scrollbar-width: auto; /* Firefox */
}

.gSXMmp,
.shiftRoasterTable,
.hoMdai,
.jRYfDi,
.sc-dmRaPn {
  max-height: 360px !important;
  /* -ms-overflow-style: none; Internet Explorer 10+ */
  /* scrollbar-width: none; Firefox */
}
.gSXMmp::-webkit-scrollbar,
.multipleCheckboxDropdown::-webkit-scrollbar,
.printBody::-webkit-scrollbar,
.tableHeight::-webkit-scrollbar,
.shiftRoasterTable::-webkit-scrollbar,
.hoMdai::-webkit-scrollbar,
.sc-dmRaPn::-webkit-scrollbar,
.jRYfDi::-webkit-scrollbar {
  height: 0.5rem;
  width: 0.5rem;
  /* display: none; */
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* .gSXMmp:hover::-webkit-scrollbar,
.multipleCheckboxDropdown:hover::-webkit-scrollbar,
.hoMdai:hover::-webkit-scrollbar,
.shiftRoasterTable:hover::-webkit-scrollbar,
.sc-dmRaPn:hover::-webkit-scrollbar,
.printBody:hover::-webkit-scrollbar,
.jRYfDi:hover::-webkit-scrollbar {
  display: block !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
} */

.gSXMmp::-webkit-scrollbar-track,
.multipleCheckboxDropdown::-webkit-scrollbar-track,
.hoMdai::-webkit-scrollbar-track,
.tableHeight::-webkit-scrollbar-track,
.shiftRoasterTable::-webkit-scrollbar-track,
.sc-dmRaPn::-webkit-scrollbar-track,
.printBody::-webkit-scrollbar-track,
.jRYfDi::-webkit-scrollbar-track {
  background-color: transparent;
}

.gSXMmp::-webkit-scrollbar-thumb,
.multipleCheckboxDropdown::-webkit-scrollbar-thumb,
.hoMdai::-webkit-scrollbar-thumb,
.shiftRoasterTable::-webkit-scrollbar-thumb,
.tableHeight::-webkit-scrollbar-thumb,
.sc-dmRaPn::-webkit-scrollbar-thumb,
.printBody::-webkit-scrollbar-thumb,
.jRYfDi::-webkit-scrollbar-thumb {
  background-color: #a6a6a6;
  border-radius: 1rem;
  width: 40px;
}

.btn-outline-primary {
  border-color: var(--button-color);
  color: var(--button-color);
}
.btn-outline-primary:hover {
  background: var(--button-color);
  color: #fff;
  border-color: var(--button-color);
}
.btn-primary {
  background: var(--button-color);
}

/* .container-fluid {
  position: relative;
  z-index: 0;
} */

.MuiInputBase-root {
  padding: 0 !important;
}
.multipleCheckbox {
  position: relative;
  vertical-align: middle !important;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.multipleCheckbox .shiftListWidth {
  width: 160px;
  overflow: auto;
  text-align: left;
  margin-right: 1rem;
  padding: 0.5rem 0;
}

.multipleCheckbox .shiftListWidth .span {
  /* border: 1px solid rgba(0, 0, 0, 0.12); */
  padding: 0.3rem 0;
  /* border-radius: 500px; */
}
.multipleCheckbox .multipleCheckboxDropdown {
  position: absolute;
  background: #fff;
  padding: 0.5rem 1rem;
  top: 33px;
  right: 15px;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  width: max-content;
}
.multipleCheckbox .multipleCheckboxDropdown label {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  text-align: left;
  cursor: pointer;
}
.multipleCheckbox.tablecell {
  padding: 0 1rem !important;
}
.multipleCheckbox .MuiInputBase-input {
  background-color: transparent !important;
}
.MuiInputBase-input {
  padding: 5px 0.5rem !important;
  font-size: 13px !important;
  background-color: #efefef7d !important;
  border-radius: 3px !important;
}
.MuiAutocomplete-tag,
.css-1q60rmi-MuiAutocomplete-endAdornment {
  display: none !important;
  top: calc(50% - 14px) !important;
}

/* .multipleCheckbox .MuiButtonBase-root {
  display: block !important;
}
.MuiButtonBase-root {
  display: none !important;
} */
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

/* ------------- pop up ----------*/
.popup-wrapper {
  min-width: 100vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  z-index: 20000;
  background: rgba(0, 0, 0, 0.75);
  display: none;
}

.popup-inner {
  display: none;
  width: 40%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  z-index: 1000;
  background: #fff;
}

.popUpBody {
  padding-bottom: 5rem;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

@media screen and (max-width: 900px) {
  .popup-inner {
    width: 70% !important;
    /* top: 9vh;
      left: 15%; */
  }
}

@media screen and (max-width: 577px) {
  .popup-inner {
    width: 90% !important;
    /* top: 9vh;
      left: 5%; */
  }
}

.form-check-input {
  cursor: pointer;
}
.form-check-label {
  cursor: pointer;
}

.high {
  z-index: 2 !important;
}
.low {
  z-index: 1 !important;
}

@media print {
  .printable-text {
    color: white !important;
  }
}

/* ---------- */

.timeoutBg {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 5000;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
}

.timeoutPop {
  background-color: white;
  width: 50%;
  height: auto;
  position: absolute;
  top: 50%;
  max-height: 90vh;
  overflow: auto;
  overflow-x: hidden;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

.timeoutPop-body {
  padding: 15px 60px;
  padding-top: 20px;
}

.timeoutPop-footer {
  padding: 0.5rem;
}

/* ------- */

.newpopup-bg {
  min-width: 100vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  z-index: 20000;
  background: rgba(0, 0, 0, 0.75);
  display: none;
}

.newpopup {
  display: none;
  width: 40%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  z-index: 1000;
  background: #fff;
}

.newpopup-head,
.popUpHeader {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--button-color);
  width: 100%;
  position: relative;
  padding: 10px 10px;
  color: #fff;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  z-index: 50;
  align-items: center;
}
.newpopup-head .popUpTitle,
.popUpHeader .popUpTitle {
  font-size: 15px;
  padding: 0px 15px;
}
.newpopup-head .popUpTitle,
.popUpHeader .popUpTitle {
  padding-right: 15px;
}
.newpopup-body {
  /* padding: 1rem; */
  padding-bottom: 5rem;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 3rem;
}
.new-footer,
.ln-popUpFooter {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  /* background: #fff; */
  background: #fbfbfb;
  padding: 0.3rem 0;
}

@media screen and (max-width: 900px) {
  .newpopup {
    width: 70% !important;
    /* top: 9vh;
    left: 15%; */
  }
}

@media screen and (max-width: 577px) {
  .newpopup-bg {
    width: 90% !important;
    /* top: 9vh;
    left: 5%; */
  }
}

.Toastify__toast-container {
  z-index: 100000 !important;
}

.custom__table {
  width: 100%;
  min-width: max-content;
}

table td::first-letter,
table th::first-letter {
  text-transform: uppercase;
}

.label-radio {
  height: 30px;
  width: max-content;
  border: 1px solid var(--color);
  margin: auto;
  border-radius: 3px;
  position: relative;
  color: var(--color);
  background-color: white;
  transition: 0.5s;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
}

.label-radio-routine {
  height: 30px;
  width: max-content;
  border: 1px solid var(--color);
  margin: auto;
  border-radius: 3px;
  position: relative;
  color: var(--color);
  background-color: white;
  transition: 0.5s;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
}

input[type="radio"]:checked + label {
  background-color: var(--color);
  color: #ffffff;
}

/* input[type="radio"]{
  -webkit-appearance: none;
} */
.radio--button {
  -webkit-appearance: none;
}

.view__table {
  display: grid;
  row-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
}
.view__table > div {
  grid-column: span 12;
}
.view__table > div:first-child {
  grid-column: span 6;
}
.view__table > div:nth-child(2) {
  grid-column: span 6;
}
.view__table > div:nth-child(5),
.view__table > div:nth-child(6),
.view__table > div:nth-child(7),
.view__table > div:nth-child(8) {
  grid-column: span 3;
}

.the__title {
  font-weight: bold;
  font-size: 12px;
  margin: 0;
}
.the__content {
  font-size: 12px;
  color: #212529;
  margin: 10px 0;
}
.comment__list {
  margin: 10px 0 20px 0;
  height: 49vh;
  overflow: auto;
}
.comment__list .comment__wrapper:not(:last-child) {
  border-bottom: 1px solid #ced4da;
  padding-bottom: 15px;
  margin-bottom: 10px;
}
.comment__wrapper span {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.comment__wrapper span > svg {
  color: #555;
  margin-right: 3px;
}
.comment__form {
  position: fixed;
  bottom: 45px;
  width: 95%;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
}
.remove__comment {
  color: rgb(247, 32, 32);
  text-decoration: underline;
  cursor: pointer;
  margin-right: 10px;
}
.comment__tab {
  position: sticky;
  top: -1px;
}
/* @media (max-width: 767px) {
  .notification-model-content.helpdesk__content {
    height: 74vh;
  }
} */
@media (max-width: 640px) {
  .view__table {
    column-gap: 20px;
  }
  .view__table > div {
    grid-column: span 6 !important;
  }
}
.view__table__img img {
  /* width: 100%; */
  max-width: 300px;
  object-fit: cover;
}

.z-index-1 {
  z-index: 1 !important;
}
.quill {
  height: 250px !important;
}

fieldset {
  border: 1px solid #d8d1d1;
  padding: 10px;
  margin-top: 20px;
}
legend {
  width: max-content;
  margin: -20px 0;
  padding: 0 5px;
  background: #fff;
  font-size: 14px;
  font-weight: 600;
}

.promotion__table .rdt_TableHeadRow > div:nth-child(2),
.promotion__table .rdt_TableRow > div:nth-child(2) {
  position: sticky;
  left: 0;
  background: #004aad;
  z-index: 1;
}
.promotion__table .rdt_TableRow > div:nth-child(2) {
  background: #fff;
}
.promotion__table .rdt_TableRow:nth-of-type(odd) > div:nth-child(2) {
  background-color: #f8f8ff;
}
.promotion__table table{
  width: max-content;
  max-width: max-content;
}
.promotion__table .tableHead.sticky-left{
  z-index: 100;
}
.promotion__table .tableHead {
  top: -1px;
  position: sticky;
}

.promotion__table thead tr:last-child .tableHead{
  top: 39px;
} 